import * as React from "react"

import Layout from "../components/layout/layout"
import Footer from "../components/footer/footer"
import Hero from "../components/hero/hero"
import Topborder from "../components/topborder/topborder"
import Seo from "../components/seo"
import Bodytext from "../components/bodytext/bodytext"
import Deets from "../components/deets/deets"

const IndexPage = () => (
  <Layout>
    <Seo title="Silana Consultancy Ltd | London Financial Services Compliance" />
    <Topborder />
    <Hero />
    <Bodytext />
    <Deets />
    <Footer />
  </Layout>
)

export default IndexPage
