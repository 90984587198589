import * as React from "react"
import "./footer.scss"
const Footer = () => (
  <div id="footer" className="wrapper row5">
    <div id="copyright" className="hoc clear">
      <p>
        &copy; 2021 Silana Consultancy Ltd, All Rights Reserved
        <br />
        Silana Consultancy Ltd is a company registered in England, reg no.
        10864787, registered address: CRO 581, Nexus Connect,
        Esh,
        Durham
        DH7 9QR
      </p>
    </div>
  </div>
)

export default Footer
