import * as React from "react"
import "./bodytext.scss"

const Bodytext = () => {
  return (
    <section className="hoc container clear">
      <div className="bodytext">
        <p>
          Silana Consultancy represents a unique presence in the regulation of
          banking and financial services offering market and regulatory
          experience. Silana's aim is to provide sound compliance support and
          hands-on solutions. We recognise that compliance should go hand in
          hand with risk management and good business practice.
        </p>
      </div>
      <div className="bodytext">
        <p>
          Silana Consultancy provides a wide range of Financial Services
          Compliance and regulatory support across banking and financial
          institutions. We enable you to stay within the regulations in the most
          cost-effective, timely and practical manner, whilst not stifling your
          business.
        </p>
      </div>
      <div className="bodytext">
        <p>
          Silana Consultancy’s compliance professionals are former regulators and
          industry professionals with significant industry knowledge and an
          understanding of commercial business concerns. Our experience is
          gained from employment in the financial services and banking industry.
          Our close links with risk management specialists, auditors and law
          firms enable us to go beyond the pure compliance aspects of your
          business
        </p>
      </div>
    </section>
  )
}
export default Bodytext
