import * as React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faEnvelope,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"

import "./deets.scss"

const Deets = () => {
  return (
    <section id="companydeets" class="wrapper row3">
      <div class="hoc container clear">
        <div class="deetscard">
          <h2>Our Services</h2>
          <ul>
          <li>
              <FontAwesomeIcon icon={faCheck} /> Multi Juristictional Client Awareness
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Data Analysis
            </li>
            <li><FontAwesomeIcon icon={faCheck} /> Consumer Duty Policies & Implementation Plan</li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Investigations
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Authorisation -FCA
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Authorisation - PRA
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Compliance Report Writing
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Compliance Monitoring and Advisory
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Compliance Support
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Compliance  &amp; AML Training
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Internal Audits
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> Due Diligence
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} /> File Review
            </li>
          </ul>
        </div>
        <div class="deetscard">
          <h2>Some Of Our Clients</h2>
          <ul>
          <li>
              <FontAwesomeIcon icon={faChevronRight} /> Primis
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Infrastructure Bank
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Insurance Company
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Citizens Advice Bureau
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Financial Conduct
              Authority
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Retail Investment / Banks
            </li>
            <li>
              <FontAwesomeIcon icon={faChevronRight} /> Consumer Credit
              Firms
            </li>
          </ul>
        </div>
        <div class="deetscard">
          <h2>Contact Details</h2>
          <address>
            <b>Silana Consultancy Ltd</b><br/>
            CRO 581<br />
            Nexus Connect<br />
            Esh<br />
            Durham<br />
            DH7 9QR<br />
          </address>
          <ul>
            <li>
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                title="Send an email enquiry"
                href="mailto:silana.consultancy@gmail.com"
              >
              silana.consultancy@gmail.com
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faLinkedinIn} />{" "}
              <a
                rel="noreferrer"
                target="_blank"
                title="Silana Consultancy LinkedIn"
                href="https://www.linkedin.com/in/fiona-daniel-3a393142"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
export default Deets
