import * as React from "react"
import "./hero.scss"

const Hero = () => {
  return (
    <div id="hero" class="wrapper bgded overlay">
      <div id="pageintro" className="hoc clear">
        <article>
          <h1>
            Silana Consultancy Ltd
            <span> | London Financial Compliance Consultancy</span>
          </h1>
          <h2>Financial Services Compliance</h2>
        </article>
      </div>
    </div>
  )
}

export default Hero
